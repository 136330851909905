.search-tab{
    border: 1px solid black;    
    width: 60vw;
    background-color: #333333;
    /* background-color: white; */
    padding: 5px 10px;
}
.search-col{
    /* width: 100%; */
    height: 100%;
}
/* select,input{
    background-color: #333333;
    color: #fef952;
    padding-right: 10px;
    border: 0;
    border-right: 1px solid white;
    width: 200px;
    justify-content: center;
} */
button{
    border: 0;
    background-color: #333333;
    color: #fef952;
    width:220px
}
.search-col-search{
    display: flex;
    
}