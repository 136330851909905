.list-group-item {
  background-color: rgb(212, 212, 77); /* Yellow background color */
  color: #2f2a2a; /* Black text color */
  padding: 10px; /* Adjust padding as needed */
  margin-bottom: 10px; /* Add margin between list items */
  border-radius: 10px; /* Rounded corners */
}
.fixed-bottom-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #433f3f;
  color: #fef952;
  text-align: center;
  font-weight: 600;
}
/* Media query for small screens */
@media (max-width: 576px) {
  .list-group-item {
      font-size: 14px; /* Decrease font size for small screens */
  }
}

/* Media query for medium screens */
@media (min-width: 576px) and (max-width: 768px) {
  .list-group-item {
      padding: 8px; /* Decrease padding for medium screens */
  }
}

/* Media query for large screens */
@media (min-width: 768px) {
  .list-group-item {
      background-color: black; /* Black background color for large screens */
      color: yellow; /* Yellow text color for large screens */
      padding: 12px; /* Increase padding for large screens */
  }
}
