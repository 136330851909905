
.vs {
    font-weight: 600;
  }

  .lead{
    font-size: large;
    font-weight:400;
  }
  .border-less {
    border:#0e577b
  }
  
  .date-tab {
    border: 1px solid black;
    background-color: #F5F7F8;
    padding: 8px;
    margin-bottom: 7px;
  }
  

    .list-group-item-checkslots {
      list-style: none;
    background-color: #F5F7F8 !important;
      border: none;
      padding: 15px 10px;
      border: 1px solid #45474B;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  
  
  /* .list-group-item:hover {
    background-color: #f0f0f0; /* Light gray on hover 
  } */
  
  .list-group-item input[type="text"] {
    background-color: white; /* White background for input fields */
    border: 1px solid #ccc; /* Light border */
    border-radius: 5px; /* Rounded corners */
    padding: 8px 12px; /* Padding for better appearance */
    margin-right: 10px; /* Add some space between inputs */
  }
  
  .list-group-item .vs {
    margin: 0 10px; /* Adjust margin for vs */
  }
  
  .list-group-item button {
    padding: 6px 12px; /* Padding for button */
  }
  
  .slotName-container{
    display: flex;
    justify-content: space-between;
  }

  