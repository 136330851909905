 
.form-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(65, 63, 63, 0.5); /* Semi-transparent background overlay */
  overflow: auto; /* Allow scrolling if content extends */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-heading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* background: #fff;
   */
  color: white;
  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1; /* Ensure it's above the form container */
}

.form-container {
  margin-top: 170px; /* Adjust to leave space for the fixed heading */
  background: #F5F7F8; /* Black background */
  padding: 20px;
  max-width: 400px; /* Adjust the max-width as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: 2px solid #F4CE14; /* Yellow border */
}

/* Form label styles */ 
.form-label {
  color: #45474B; /* Yellow color for labels */
}

/* Form control styles */
.form-control {
  /* background-color: #333; Dark background color for form controls */
  border-color: #45474B; /* Yellow border color for form controls */
}

/* Button styles */
.btn-primary {
  background-color: #F4CE14; /* Yellow background color for primary button */
  border-color: #45474B; /* Yellow border color for primary button */
  color: #45474B; /* Black text color for primary button */
}

.btn-secondary {
  background-color: #495E57; /* Black background color for secondary button */
  border-color: #45474B; /* Yellow border color for secondary button */
  color: #F4CE14; /* Yellow text color for secondary button */
}

/* Responsive styles using Bootstrap classes */
@media (max-width: 768px) {
  .form-container {
      max-width: 90%; /* Adjust for smaller screens */
  }
}

@media (max-width: 576px) {
  .form-container {
      max-width: 100%; /* Adjust for extra small screens */
  }
}
