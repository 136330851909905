/* .card-img-left {
  width: 45%;
  background: scroll center url('https://source.unsplash.com/WEQbe2jBg40/414x512');
  background-size: cover;
} */

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: rgb(60, 60, 60) !important;
  font-weight: 600;
}

.create-account{
    width: 100%;
}

.login-text{
    margin-left: 27px;
    /* margin-top: 20px; */
    /* margin-bottom: 50px; */
    font-size:22px;
    color: white;
}

.login-text>span{
    /* background-color: #333333; */
    color: #fef952;
}
.title-div{
    background-color: #333333;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 50px;

}
.create-account-btn{
  width: 100%;
}